import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/content.js";
import Link from 'src/components/design-system/link';
import SEO from '../components/seo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="L'association" mdxType="SEO" />
    <h2>{`L'association`}</h2>
    <p>{`L’ENFANT D’EAU est une association selon la loi de 1901 (à but non lucratif) qui existe depuis 1988. Son équilibre financier est assuré par les inscriptions des adhérents. La Mairie de Paris lui concède moyennant paiement l’usage des installations du centre sportif Jean Dame.`}</p>
    <p>{`Chacun des parents participant à l’activité est invité à s’impliquer bénévolement dans sa gestion (en prenant part au bureau, au conseil d’administration) ou de façon plus ponctuelle. En tout état de cause, la participation de tous les parents à l’assemblée générale annuelle est vivement encouragée.`}</p>
    <p>{`Le conseil d’administration emploie une équipe pluridisciplinaire d’accueillants (obligatoirement trois par séance), qui sont maîtres-nageurs et/ou spécialistes de la petite enfance (éducatrice de jeunes enfants, psychologues, psychomotricienne), et dont la fonction est de vous accompagner, vous et votre enfant, au cours de l’activité.`}</p>
    <p>{`Le conseil d’administration a, par ailleurs, pour rôle de définir les grandes orientations de l’association, de déléguer la démarche pédagogique à l’équipe d’animateurs et de préparer les délibérations de l’assemblée générale. Le conseil d’administration est élu pour deux ans, avec un renouvellement annuel de la moitié des administrateurs.`}</p>
    <p>{`Chaque année, le conseil d'administration élit son Bureau. Le Bureau, autour du président, assure la gestion de l’association et les relations avec les animateurs et les administrations.`}</p>
    <p>{`L’Enfant d’Eau existe depuis 1988 et depuis le début, des parents bénévoles se relaient pour préserver, pérenniser notre activité. Notre joie est de voir la satisfaction des nombreux enfants qui ont pratiqué et qui pratiquent cette activité.`}</p>
    <p>{`C’est avec les compétences de chacun (parents, animateurs et Conseil d’Administration) que nous allons continuer de permettre à nos enfants de pratiquer cette activité.`}</p>
    <p>{`Pour en savoir plus sur l'association et son fonctionnement, vous pouvez `}<Link href="/statuts-lenfantdeau-2022.pdf" mdxType="Link">{`télécharger les statuts`}</Link></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      